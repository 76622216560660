import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "overflow-visible",
  ref: "formRef"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mt-10 flex flex-row justify-between md:justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.formState.generalError)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "w-3/5 p-3 mx-auto mb-3 bg-red-100 rounded-2xl text-red-400 text-center text-medium font-semibold",
          innerHTML: _ctx.formState.generalError
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_spinner, {
          key: 1,
          class: "mx-auto"
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("form", {
          key: 2,
          class: "pr-1 md:pr-0 w-full text-black w-full relative",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.structure, (innerElementData) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.formElementsMap(innerElementData.type)), {
              elementData: innerElementData,
              values: _ctx.formState.value,
              initialState: _ctx.prepareInitialState(innerElementData, _ctx.formState),
              key: innerElementData.id + '_' + new Date().getTime(),
              onFormInputValueUpdate: _ctx.formInputValueUpdate
            }, null, 40, ["elementData", "values", "initialState", "onFormInputValueUpdate"]))
          }), 128)),
          _createElementVNode("section", null, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_default_button, {
                type: "outline",
                class: "mb-4",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelAction')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("parcels.create.buttons.cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_default_button, {
                injectedClass: "w-40 md:w-auto md:ml-5 py-4 px-4",
                "button-role": "submit",
                isLoading: _ctx.isSaving,
                onClick: _withModifiers(_ctx.submit, ["stop"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.submitButtonLabel), 1)
                ]),
                _: 1
              }, 8, ["isLoading", "onClick"])
            ])
          ])
        ], 32))
      : _createCommentVNode("", true)
  ], 512))
}