import { AxiosPromise } from "axios";
import axiosClient from "@/axios";
import { ProductsResponse } from "@/models/ProductsResponse";
import { ResourcesFilterParams } from "@/models/ResourcesFilterParams";
import _ from "lodash";

const productsService = {
  get(params?: ResourcesFilterParams): AxiosPromise<ProductsResponse> {
    return axiosClient.get("/products", {
      params: _.omit(params, ["product_names"]),
    });
  },
  getUserProducts(
    countryId?: string | number,
    supplierId?: string | number,
    type = "outbound"
  ): AxiosPromise<ProductsResponse> {
    return axiosClient.get(
      `/users/me/supplier_products?country_id=${countryId}&supplier_id=${supplierId}&type=${type}`
    );
  },
};

export default productsService;
