
import { defineComponent, ref } from "vue";
import { Parcel } from "@/models/Parcel";
import Spinner from "@/components/ui/Spinner.vue";
import BulkParcelErrorsListItem from "@/components/bulk-parcels/BulkParcelErrorsListItem.vue";
import Modal from "@/components/ui/Modal.vue";
import parcelsService from "@/services/parcels.service";
import { PaginationMeta } from "@/models/PaginationMeta";
import Pagination from "@/components/ui/Pagination.vue";
import { useRoute } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { FormElementData } from "@/models/FormElementData";
import DynamicForm from "@/components/form/DynamicForm.vue";
import {
  prepareParcelFormData,
  prepareParcelFormStatus,
} from "@/common/ParcelFormTools";

export default defineComponent({
  name: "BulkParcelsErrors",
  components: {
    DynamicForm,
    Pagination,
    Modal,
    BulkParcelErrorsListItem,
    Spinner,
  },
  setup() {
    const isLoading = ref<boolean>(false);
    const showEditModal = ref<boolean>(false);
    const parcelsWithErrors = ref<Parcel[]>([]);
    const pagination = ref<PaginationMeta | undefined>(undefined);
    const formLoading = ref<boolean>(true);
    const formSaving = ref<boolean>(false);
    const editingParcelId = ref<number>(0);
    const formData = ref<FormElementData>({
      id: "empty-form",
      type: "form",
    });
    const formValue = ref<unknown>({});
    const formValidation = ref<unknown>({});
    const route = useRoute();

    const submitAction = (values: Record<string, unknown>) => {
      if (editingParcelId.value) {
        formSaving.value = true;
        parcelsService
          .update(editingParcelId.value, values)
          .then(() => {
            showEditModal.value = false;
            getList(
              pagination.value?.current_page || 1,
              pagination.value?.per_page || 10
            );
          })
          .catch((error) => {
            formValidation.value = error?.response?.data?.details ?? {};
            document.getElementsByClassName("modal__content")[0].scrollTo(0, 0);
          })
          .finally(() => {
            formSaving.value = false;
          });
      }
    };
    const editParcel = async (id: number) => {
      editingParcelId.value = id;
      showEditModal.value = true;
      formLoading.value = true;
      formValue.value = {};
      formValidation.value = {};
      const { value, validation } = await prepareParcelFormStatus(id);
      formValue.value = value;
      formValidation.value = validation;
      formLoading.value = false;
    };
    const getList = async (page = 1, perPage = 10) => {
      const { data } = await parcelsService.list({
        page: page,
        per_page: perPage,
        bulk_parcel_id: route.params.id.toString(),
        with_errors: true,
      });

      parcelsWithErrors.value = data.data || [];
      pagination.value = data.meta;
    };

    onMounted(async () => {
      isLoading.value = true;
      await getList();
      formData.value = await prepareParcelFormData({});
      isLoading.value = false;
    });

    return {
      isLoading,
      showEditModal,
      parcelsWithErrors,
      pagination,
      formLoading,
      formSaving,
      formData,
      formValue,
      formValidation,
      submitAction,
      editParcel,
      getList,
    };
  },
});
