import { AxiosPromise } from "axios";
import axiosClient from "@/axios";
import { CountriesResponse } from "@/models/CountriesResponse";
import { ResourcesFilterParams } from "@/models/ResourcesFilterParams";
import _ from "lodash";

const countriesService = {
  get(params?: ResourcesFilterParams): AxiosPromise<CountriesResponse> {
    return axiosClient.get("/countries", {
      params: _.omit(params, ["country_ids"]),
    });
  },
  getEuCountries(): AxiosPromise<CountriesResponse> {
    return axiosClient.get("/countries/eu");
  },
  getUserCountries(): AxiosPromise<CountriesResponse> {
    return axiosClient.get(`/users/me/supplier_countries`);
  },
};

export default countriesService;
