import { AxiosPromise } from "axios";
import axiosClient from "@/axios";
import { ParcelsResponse } from "@/models/ParcelsResponse";
import { ParcelResponse } from "@/models/ParcelResponse";
import { ParcelResourceRequest } from "@/models/ParcelResourceRequest";
import { ParcelsParams } from "@/models/ParcelsParams";
import { DownloadFileResponse } from "@/models/DownloadFileResponse";
import { ReturnParcelResponse } from "@/models/ReturnParcelResponse";
import { Values } from "@/common/FormTools";

const parcelsService = {
  list(params: ParcelsParams): AxiosPromise<ParcelsResponse> {
    return axiosClient.get("/parcels", {
      params: params,
    });
  },
  details(parcelId: number): AxiosPromise<ParcelResponse> {
    return axiosClient.get(`/parcels/${parcelId}`);
  },
  save(request: ParcelResourceRequest): AxiosPromise<ParcelResponse> {
    return axiosClient.post("/parcels", request);
  },
  update(
    parcelId: number,
    request: ParcelResourceRequest
  ): AxiosPromise<ParcelResponse> {
    return axiosClient.put(`/parcels/${parcelId}`, request);
  },
  createReturnParcel(
    parcelId: string | number,
    returnParcelData: Values
  ): AxiosPromise<ReturnParcelResponse> {
    return axiosClient.post(`/parcels/${parcelId}/return`, returnParcelData);
  },
  downloadLabels(parcelsIds: number[]): AxiosPromise {
    return axiosClient.post("/parcels/labels", {
      ids: parcelsIds,
    });
  },
  downloadManifest(parcelsIds: number[]): AxiosPromise<DownloadFileResponse> {
    return axiosClient.post(
      "/parcels/manifests",
      {
        parcel_ids: parcelsIds,
      },
      {
        responseType: "blob",
      }
    );
  },
  exportXlsx(params: ParcelsParams): AxiosPromise {
    return axiosClient.get(
      "/parcels/export",
      {
        params: params,
        responseType: "blob"
      }
    )
  }
};

export default parcelsService;
