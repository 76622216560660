
import { defineComponent, PropType } from "vue";
import { PaginationMeta } from "@/models/PaginationMeta";
import VPagination from "@hennge/vue3-pagination";

export default defineComponent({
  name: "Pagination",
  components: { VPagination },
  data() {
    return {
      rowsCounts: [10, 20, 30],
    };
  },
  computed: {
    page: {
      get(): number {
        return this.meta.current_page || 1;
      },
      set(page: number): void {
        this.$emit("changePagination", { page, perPage: this.perPage });
      },
    },
    perPage: {
      get(): number {
        return this.meta.per_page || 10;
      },
      set(perPage: number): void {
        this.$emit("changePagination", { page: this.page, perPage });
      },
    },
  },
  props: {
    totalRows: {
      type: Number,
      required: true,
    },
    meta: {
      type: Object as PropType<PaginationMeta>,
      required: true,
    },
  },
  methods: {
    updateHandler(): void {
      this.$emit("changePage", this.page);
    },
  },
});
