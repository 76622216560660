
import { defineComponent, PropType, reactive, watch } from "vue";
import Spinner from "@/components/ui/Spinner.vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import {
  formElementsMap,
  prepareInitialState,
  Values,
  FormState,
} from "@/common/FormTools";
import _ from "lodash";
import { FormElementData } from "@/models/FormElementData";
import { Errors, EventBus } from "@/common/Tools";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "DynamicForm",
  components: { DefaultButton, Spinner },
  emits: ["submitAction", "cancelAction"],
  props: {
    formData: {
      type: Object as PropType<FormElementData>,
      required: true,
    },
    initValidation: {
      type: Object as PropType<Errors>,
      required: true,
    },
    initValue: {
      type: Object as PropType<Values>,
      required: true,
    },
    submitButtonLabel: {
      type: String,
      required: false,
      default: "Submit",
    },
    isSaving: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, context) {
    const { t } = useI18n();
    const formState = reactive({
      generalError: undefined,
      value: props.initValue,
      validation: props.initValidation,
    }) as FormState;

    watch(
      () => props.initValue,
      (initValue: Values) => {
        formState.value = initValue;
      },
      { deep: true }
    );

    watch(
      () => props.initValidation,
      (initValidation) => {
        formState.validation = initValidation;
      },
      { deep: true }
    );

    const formInputValueUpdate = (
      inputId: string,
      value: unknown,
      valuePath: string
    ) => {
      _.set(formState.value as Record<string, unknown>, valuePath, value);
      _.unset(formState.validation as Record<string, unknown>, valuePath);
    };

    const submit = () => {
      formState.generalError = undefined;
      context.emit("submitAction", formState.value);
    };

    const handleWebshipperError = (data: unknown) => {
      formState.generalError = `
        ${t("parcels.general_errors.webshipper")}
        <br/>
        ${data as string}
      `;
    };

    EventBus.on("webshipperError", handleWebshipperError);

    return {
      formState,
      formInputValueUpdate,
      formElementsMap,
      prepareInitialState,
      submit,
    };
  },
});
