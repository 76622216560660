import { AxiosPromise } from "axios";
import { SuppliersResponse } from "@/models/SuppliersResponse";
import axiosClient from "@/axios";
import { ResourcesFilterParams } from "@/models/ResourcesFilterParams";
import _ from "lodash";

const suppliersService = {
  get(params?: ResourcesFilterParams): AxiosPromise<SuppliersResponse> {
    return axiosClient.get("/suppliers", {
      params: _.omit(params, ["supplier_ids"]),
    });
  },
  getUserSuppliers(
    countryId?: string | number
  ): AxiosPromise<SuppliersResponse> {
    return axiosClient.get(`/users/me/suppliers?country_id=${countryId}`);
  },
};

export default suppliersService;
