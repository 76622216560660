
import { defineComponent } from "vue";
import ExpandButton from "@/components/ui/ExpandButton.vue";
import { prepareErrors } from "@/common/Tools";

export default defineComponent({
  name: "BulkParcelErrorsListItem",
  components: {
    ExpandButton,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    prepareErrors,
  },
});
