export interface Currency {
  name: string;
  code: string;
}

export const currencies: Currency[] = [
  {
    name: "Euro",
    code: "EUR",
  },
  {
    name: "US Dollar",
    code: "USD",
  },
];
