import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7150d7e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination relative" }
const _hoisted_2 = { class: "pagination__rows-count md:w-1/2 flex justify-center md:justify-start mb-4 md:mb-0" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "pagination__per-page flex absolute w-full justify-center md:justify-center mb-4 md:mb-0" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "pagination__pages md:w-1/2 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.$t("pagination.count.showing")) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.meta.from) + " - " + _toDisplayString(_ctx.meta.to), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("pagination.count.of_rows", { number: _ctx.meta.total_rows })), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.$t("pagination.per_page.show")) + " ", 1),
        _withDirectives(_createElementVNode("select", {
          class: "font-bold",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.perPage) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rowsCounts, (rowsCount) => {
            return (_openBlock(), _createElementBlock("option", {
              key: rowsCount,
              value: rowsCount
            }, _toDisplayString(rowsCount), 9, _hoisted_5))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.perPage]
        ]),
        _createTextVNode(" " + _toDisplayString(_ctx.$t("pagination.per_page.row_per_page")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_v_pagination, {
        modelValue: _ctx.page,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
        pages: _ctx.meta.total_pages,
        "hide-first-button": true,
        "hide-last-button": true,
        rangeSize: 2
      }, null, 8, ["modelValue", "pages"])
    ])
  ]))
}