import { FormElementData } from "@/models/FormElementData";
import _ from "lodash";
import { currencies } from "@/common/Currency";
import parcelsService from "@/services/parcels.service";
import suppliersService from "@/services/suppliers.service";
import pickupPointService from "@/services/pickup-point.service";
import productsService from "@/services/products.service";
import countriesService from "@/services/countries.service";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import {
  SelectOption,
  CodeOption,
  CodeOptions,
  Values,
  PossibleValues,
  prepareOptionsId,
  prepareCodes,
  FormState,
} from "@/common/FormTools.ts";
import { Country } from "@/models/Country";

export const formatFormValues = (values: Values): Values => {
  return _.transform(
    values,
    (newValues: Values, value: PossibleValues, key: string) => {
      if (key === "country" && _.isPlainObject(value)) {
        _.set(newValues, "country_id", (value as Country).id);
        return;
      }
      if (_.isPlainObject(value)) {
        _.set(newValues, key, formatFormValues(value as Values));
        return;
      }
      if (_.isNumber(value)) {
        _.set(newValues, key, value.toString());
        return;
      }
      _.set(newValues, key, value);
      return;
    },
    {} as Values
  );
};

export const extractEuCountriesIds = (options: CodeOptions): number[] => {
  return _.reduce(
    options,
    (Ids: number[], option: CodeOption) => {
      if (option?.id) {
        Ids.push(option.id);
      }
      return Ids;
    },
    [] as number[]
  );
};

export const extractUkId = (options: CodeOptions): number => {
  return _.find(options, { code: "GB" })?.id ?? 0;
};

export const extractDkId = (options: CodeOptions): number => {
  return _.find(options, { code: "DK" })?.id ?? 0;
};

export const outsideEuParcel = (euCountriesIds: number[]) => {
  return (values: Values): boolean => {
    const senderCountryId = _.get(values, "shipper_address.country_id", false);
    const receiverCountryId = _.get(
      values,
      "receiver_address.country_id",
      false
    );
    return (
      (!!senderCountryId && !_.includes(euCountriesIds, senderCountryId)) ||
      (!!receiverCountryId && !_.includes(euCountriesIds, receiverCountryId))
    );
  };
};

export const parcelToCountryId = (countryId: number) => {
  return (values: Values): boolean => {
    const receiverCountryId = _.get(values, "receiver_address.country_id");
    return receiverCountryId === countryId;
  };
};

export const receiverVatRequirement = (
  euCountriesIds: number[]
): ((values: Values) => boolean) => {
  return (values: Values): boolean => {
    return (
      !!_.get(values, "receiver_address.company_name", false) &&
      outsideEuParcel(euCountriesIds)(values)
    );
  };
};

export const receiverEoriRequirement = (
  ukId: number
): ((values: Values) => boolean) => {
  return (values: Values): boolean => {
    return (
      !!_.get(values, "receiver_address.company_name", false) &&
      parcelToCountryId(ukId)(values)
    );
  };
};

export const getSupplierOptions = async (
  values: Values
): Promise<SelectOption[]> => {
  let options = [] as SelectOption[];
  const countryId = _.get(values, "receiver_address.country_id", false);
  if (countryId) {
    await suppliersService
      .getUserSuppliers(countryId.toString())
      .then((response) => {
        options = prepareOptionsId(response.data.suppliers);
      });
  }
  return options;
};

export const getProductOptions = async (
  values: Values
): Promise<SelectOption[]> => {
  let options = [] as SelectOption[];
  const countryId = _.get(values, "receiver_address.country_id", false);
  const supplierId = _.get(values, "supplier_id", false);
  if (countryId && supplierId) {
    await productsService
      .getUserProducts(countryId.toString(), supplierId.toString())
      .then((response) => {
        options = prepareOptionsId(response.data.products);
      });
  }
  return options;
};

export const getPickupPointOptions = async (
  values: Values
): Promise<SelectOption[]> => {
  let options = [] as SelectOption[];
  const countryId = _.get(values, "receiver_address.country_id", false);
  const supplierId = _.get(values, "supplier_id", false);
  const zipCode = _.get(values, "receiver_address.zip_code", false);
  const address = _.get(values, "receiver_address.address_first_line", false);
  const accountDetails = JSON.parse(
    localStorage.getItem("accountDetails")?.toString() ?? "{}"
  );
  if (countryId && supplierId) {
    await pickupPointService
      .getPickupPoints(
        countryId.toString(),
        supplierId.toString(),
        zipCode.toString(),
        address.toString(),
        accountDetails
      )
      .then((response) => {
        localStorage.setItem("pickupPoints", JSON.stringify(response.data));
        options = _.map(response.data, (option: any) => ({
          value: option?.code ?? "Empty",
          label: _.compact([
            option?.name,
            option?.pickup_point?.distance + " m",
            option?.address_first_line,
            option?.address_second_line,
            option?.zip_code,
          ]).join(" - "),
        }));
      });
  }
  return options;
};

export const prepareParcelFormData = async (
  accountDetailsParam: AccountDetailsResponse
): Promise<FormElementData> => {
  let countryCodes = [] as SelectOption[];
  let ukId = 0;
  let dkId = 0;
  let euCountriesIds = [] as number[];
  let euCountries = [] as SelectOption[];
  let userCountries = [] as SelectOption[];
  localStorage.setItem("accountDetails", JSON.stringify(accountDetailsParam));

  return new Promise((resolve) => {
    Promise.all([
      countriesService.get().then((response) => {
        countryCodes = prepareCodes(response.data.countries);
        ukId = extractUkId(response.data.countries);
        dkId = extractDkId(response.data.countries);
      }),
      countriesService.getEuCountries().then((response) => {
        euCountriesIds = extractEuCountriesIds(response.data.countries);
        euCountries = prepareOptionsId(response.data.countries);
      }),
      countriesService.getUserCountries().then((response) => {
        userCountries = prepareOptionsId(response.data.countries);
      }),
    ])
      .then(() => {
        resolve({
          id: "parcel-form",
          type: "form",
          button: "Submit",
          structure: [
            {
              id: "parcel-form-row-1",
              type: "row",
              structure: [
                {
                  id: "receiver_address",
                  type: "section",
                  width: "1/2",
                  label: "parcels.details.receiver",
                  structure: [
                    {
                      id: "receiver_address.name",
                      type: "text",
                      label: "parcels.labels.name",
                      placeholder: "parcels.labels.name",
                      required: () => true,
                    },
                    {
                      id: "receiver_address.company_name",
                      type: "text",
                      label: "parcels.labels.company",
                      placeholder: "parcels.labels.company",
                      required: () => false,
                    },
                    {
                      id: "receiver_address.address_first_line",
                      type: "text",
                      label: "parcels.labels.first_address_line",
                      placeholder: "parcels.labels.first_address_line",
                      required: () => true,
                    },
                    {
                      id: "receiver_address.address_second_line",
                      type: "text",
                      label: "parcels.labels.second_address_line",
                      placeholder: "parcels.labels.second_address_line",
                      required: () => false,
                    },
                    {
                      id: "receiver_address.city",
                      type: "text",
                      label: "parcels.labels.city",
                      placeholder: "parcels.labels.city",
                      required: () => true,
                    },
                    {
                      id: "receiver_address.state",
                      type: "text",
                      label: "parcels.labels.state",
                      placeholder: "parcels.labels.state",
                      required: () => false,
                    },
                    {
                      id: "receiver_address.zip_code",
                      type: "text",
                      label: "parcels.labels.zip_code",
                      placeholder: "parcels.labels.zip_code",
                      required: () => true,
                    },
                    {
                      id: "receiver_address.country_id",
                      type: "select",
                      label: "parcels.labels.country_id",
                      placeholder: "parcels.labels.country_id",
                      options: () => userCountries,
                      required: () => true,
                    },
                    {
                      id: "receiver_address.phone",
                      type: "text",
                      label: "parcels.labels.phone",
                      placeholder: "parcels.labels.phone",
                      required: () => false,
                    },
                    {
                      id: "receiver_address.email",
                      type: "text",
                      label: "parcels.labels.email",
                      placeholder: "parcels.labels.email",
                      required: () => false,
                    },
                    {
                      id: "receiver_address.vat",
                      type: "text",
                      label: "parcels.labels.vat",
                      placeholder: "parcels.placeholders.vat",
                      required: receiverVatRequirement(euCountriesIds),
                    },
                    {
                      id: "receiver_address.eori",
                      type: "text",
                      label: "parcels.labels.eori",
                      placeholder: "parcels.placeholders.eori",
                      required: receiverEoriRequirement(ukId),
                    },
                  ],
                },
                {
                  id: "shipper_address",
                  type: "section",
                  width: "1/2",
                  label: "parcels.details.shipper",
                  structure: [
                    {
                      id: "shipper_address.name",
                      type: "text",
                      label: "parcels.labels.name",
                      placeholder: "parcels.labels.name",
                      required: () => false,
                    },
                    {
                      id: "shipper_address.company_name",
                      type: "text",
                      label: "parcels.labels.company",
                      placeholder: "parcels.labels.company",
                      required: () => true,
                    },
                    {
                      id: "shipper_address.address_first_line",
                      type: "text",
                      label: "parcels.labels.first_address_line",
                      placeholder: "parcels.labels.first_address_line",
                      required: () => true,
                    },
                    {
                      id: "shipper_address.address_second_line",
                      type: "text",
                      label: "parcels.labels.second_address_line",
                      placeholder: "parcels.labels.second_address_line",
                      required: () => false,
                    },
                    {
                      id: "shipper_address.city",
                      type: "text",
                      label: "parcels.labels.city",
                      placeholder: "parcels.labels.city",
                      required: () => true,
                    },
                    {
                      id: "shipper_address.state",
                      type: "text",
                      label: "parcels.labels.state",
                      placeholder: "parcels.labels.state",
                      required: () => false,
                    },
                    {
                      id: "shipper_address.zip_code",
                      type: "text",
                      label: "parcels.labels.zip_code",
                      placeholder: "parcels.labels.zip_code",
                      required: () => true,
                    },
                    {
                      id: "shipper_address.country_id",
                      type: "select",
                      label: "parcels.labels.country_id",
                      placeholder: "parcels.labels.country_id",
                      options: () => euCountries,
                      required: () => true,
                    },
                    {
                      id: "shipper_address.phone",
                      type: "text",
                      label: "parcels.labels.phone",
                      placeholder: "parcels.labels.phone",
                      required: () => false,
                    },
                    {
                      id: "shipper_address.email",
                      type: "text",
                      label: "parcels.labels.email",
                      placeholder: "parcels.labels.email",
                      required: () => false,
                    },
                    {
                      id: "shipper_address.vat",
                      type: "text",
                      label: "parcels.labels.vat",
                      placeholder: "parcels.placeholders.vat",
                      required: parcelToCountryId(ukId),
                    },
                    {
                      id: "shipper_address.eori",
                      type: "text",
                      label: "parcels.labels.eori",
                      placeholder: "parcels.placeholders.eori",
                      required: parcelToCountryId(ukId),
                    },
                  ],
                },
              ],
            },
            {
              id: "parcel-form-row-3",
              type: "row",
              structure: [
                {
                  id: "parcel-form-section-4",
                  type: "section",
                  width: "1/2",
                  label: "parcels.labels.shipping",
                  structure: [
                    {
                      id: "supplier_id",
                      type: "select",
                      label: "parcels.labels.select_supplier",
                      placeholder: "parcels.labels.select_supplier",
                      options: getSupplierOptions,
                      disabledEmptyOption: true,
                      required: () => true,
                      affectedBy: ["receiver_address.country_id"],
                    },
                    {
                      id: "product_id",
                      type: "select",
                      label: "parcels.labels.select_product",
                      placeholder: "parcels.labels.select_product",
                      options: getProductOptions,
                      disabledEmptyOption: true,
                      required: () => true,
                      affectedBy: [
                        "receiver_address.country_id",
                        "supplier_id",
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: "parcel-form-row-4",
              type: "row",
              structure: [
                {
                  id: "parcel-form-section-5",
                  type: "section",
                  label: "parcels.labels.parcel",
                  structure: [
                    {
                      id: "pickup_point_id",
                      type: "select",
                      width: "1/2",
                      label: "parcels.labels.pickup_point",
                      options: getPickupPointOptions,
                      disabledEmptyOption: false,
                      placeholder: "",
                      required: () => false,
                      affectedBy: [
                        "receiver_address.country_id",
                        "supplier_id",
                      ],
                    },
                    {
                      id: "shipper_reference",
                      type: "text",
                      width: "1/2",
                      label: "parcels.labels.shipper_reference",
                      placeholder: "general.type_here",
                      required: () => false,
                    },
                    {
                      id: "weight",
                      type: "number",
                      step: "0.01",
                      maxValue: "30",
                      appendix: "general.kg",
                      width: "1/2",
                      label: "parcels.labels.weight",
                      placeholder: "general.float_zero",
                      required: parcelToCountryId(dkId),
                    },
                    {
                      id: "parcel-form-row-5",
                      type: "row",
                      structure: [
                        {
                          id: "length",
                          type: "number",
                          step: "0.01",
                          appendix: "general.cm",
                          width: "1/3",
                          label: "parcels.labels.length",
                          placeholder: "general.float_zero",
                          required: () => false,
                        },
                        {
                          id: "width",
                          type: "number",
                          step: "0.01",
                          appendix: "general.cm",
                          width: "1/3",
                          label: "parcels.labels.width",
                          placeholder: "general.float_zero",
                          required: () => false,
                        },
                        {
                          id: "height",
                          type: "number",
                          step: "0.01",
                          appendix: "general.cm",
                          width: "1/3",
                          label: "parcels.labels.height",
                          placeholder: "general.float_zero",
                          required: () => false,
                        },
                      ],
                    },
                    {
                      id: "parcel-form-row-9",
                      type: "row",
                      structure: [
                        {
                          id: "price_value",
                          type: "number",
                          step: "0.01",
                          displayMultiplier: "0.01",
                          width: "1/3",
                          label: "parcels.labels.price_value",
                          placeholder: "general.float_zero",
                          required: outsideEuParcel(euCountriesIds),
                        },
                        {
                          id: "price_currency",
                          type: "select",
                          options: () => {
                            return _.map(currencies, (option) => ({
                              value: option.code,
                              label: option.name,
                            }));
                          },
                          width: "1/3",
                          label: "parcels.labels.price_currency",
                          placeholder: "parcels.labels.price_currency",
                          required: outsideEuParcel(euCountriesIds),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: "parcel-form-row-12",
              type: "row",
              structure: [
                {
                  id: "parcel-form-section-6",
                  type: "section",
                  label: "parcels.labels.products",
                  structure: [
                    {
                      id: "products",
                      type: "array",
                      label: "parcels.labels.products",
                      button: "parcels.create.buttons.add_product",
                      maxSectionNumber: 10,
                      required: outsideEuParcel(euCountriesIds),
                      structure: [
                        {
                          id: "description",
                          type: "text",
                          label: "parcels.labels.description",
                          placeholder: "parcels.labels.description",
                          required: () => true,
                        },
                        {
                          id: "parcel-form-row-13",
                          type: "row",
                          structure: [
                            {
                              id: "sku",
                              type: "text",
                              width: "1/2",
                              label: "parcels.labels.sku",
                              placeholder: "parcels.labels.sku",
                              required: () => true,
                            },
                            {
                              id: "hs_code",
                              type: "text",
                              width: "1/2",
                              label: "parcels.labels.hs_code",
                              placeholder: "parcels.labels.hs_code",
                              required: () => true,
                            },
                          ],
                        },
                        {
                          id: "parcel-form-row-14",
                          type: "row",
                          structure: [
                            {
                              id: "quantity",
                              type: "number",
                              keepNumber: true,
                              width: "1/2",
                              label: "parcels.labels.quantity",
                              placeholder: "parcels.labels.quantity",
                              required: () => true,
                            },
                            {
                              id: "country",
                              type: "select",
                              width: "1/2",
                              label: "parcels.labels.country",
                              placeholder: "parcels.labels.country",
                              options: () => countryCodes,
                              required: () => false,
                            },
                          ],
                        },
                        {
                          id: "parcel-form-row-15",
                          type: "row",
                          structure: [
                            {
                              id: "price_value",
                              type: "number",
                              step: "0.01",
                              width: "1/2",
                              label: "parcels.labels.price_value",
                              placeholder: "general.float_zero",
                              required: () => true,
                            },
                            {
                              id: "price_currency",
                              type: "select",
                              width: "1/2",
                              label: "parcels.labels.price_currency",
                              placeholder: "parcels.labels.price_currency",
                              options: () =>
                                _.map(currencies, (option) => ({
                                  value: option.code,
                                  label: option.name,
                                })),
                              required: () => true,
                            },
                          ],
                        },
                        {
                          id: "parcel-form-row-16",
                          type: "row",
                          structure: [
                            {
                              id: "purchase_web_address",
                              type: "text",
                              width: "1/2",
                              label: "parcels.labels.purchase_web_address",
                              placeholder:
                                "parcels.labels.purchase_web_address",
                              required: () => false,
                            },
                            {
                              id: "item_composition",
                              type: "text",
                              width: "1/2",
                              label: "parcels.labels.item_composition",
                              placeholder: "parcels.labels.item_composition",
                              required: () => true,
                            },
                          ],
                        },
                        {
                          id: "parcel-form-row-17",
                          type: "row",
                          structure: [
                            {
                              id: "cpc_code",
                              type: "text",
                              width: "1/2",
                              label: "parcels.labels.cpc_code",
                              placeholder: "parcels.labels.cpc_code",
                              required: () => false,
                            },
                            {
                              id: "warehouse_number",
                              type: "text",
                              width: "1/2",
                              label: "parcels.labels.warehouse_number",
                              placeholder: "parcels.labels.warehouse_number",
                              required: () => false,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        });
      })
      .catch(() => {
        resolve({} as FormElementData);
      });
  });
};

export const prepareParcelFormStatus = async (
  id: number
): Promise<FormState> => {
  const formState = {
    value: {},
    validation: {},
  };
  return new Promise((resolve) => {
    parcelsService.details(id).then((response) => {
      formState.validation = {
        ...response.data.validation_errors,
      };
      delete response.data.validation_errors;
      formState.value = {
        ...formatFormValues(response.data as Values),
      };
      resolve(formState);
    });
  });
};
